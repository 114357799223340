.inputs {
  margin-top: 10px;
}

.table-data {
  font-weight: bold;
}

.has-icon {
  position: absolute;
  right: -0px;
  margin-right: 5px;
  top: 4px;
  font-size: 20px;
}

.has-wrapper {
  position: relative;
}

.foxlyLogo {
  position: relative;
  text-align: center;
}

.card {
  /* height: 100vh !important; */
  /* background-image: url(https://upload.wikimedia.org/wikipedia/commons/b/b6/Image_created_with_a_mobile_phone.png); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  width: 100%;
  /* height: auto; */
}

/* .links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2px 0px;
  margin: 0px;
  line-height: 1.5;
  min-height: 48px;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  background-color: rgb(235, 238, 241);
  color: black;
  margin-bottom: 16px;
  border: none;
  border-radius: 25px;
} */
/* .links{
  min-height: 64px;
    border-radius: 31px!important;
    position: relative;
    background-color: rgb(235, 238, 241);
} */
@media (min-width: 768px) {
  .link-container-4,
  .link-container-5,
  .link-container-6 {
    min-height: 60px;
    border-radius: 31px !important;
    position: relative;
    /* border: 2px solid #D7DEE4; */
    display: flex;
    margin-bottom: 7px;
    background: #ebeef1;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 0px) {
  .links-wrapper {
    padding: 0px 15px;
    height: calc(100vh - 305px);
  }

  .links-wrapper-logo {
    padding: 0px 15px;
    height: calc(100vh - 225px);
  }
}

@media (min-width: 0px) {
  .profile-effect {
    padding: 0px 24px 0 15px;
  }
}

@media (min-width: 768px) {
  .links-wrapper {
    padding: 0px calc(15% - 9px) 0 15%;
  }

  .links-wrapper-logo {
    padding: 0px calc(15% - 9px) 0 15%;
  }
}

@media (min-width: 768px) {
  .profile-effect {
    padding: 0px 15%;
  }
}

@media (min-width: 1250px) {
  .links-wrapper {
    padding: 0px calc(18% - 9px) 0 18%;
  }

  .links-wrapper-logo {
    padding: 0px calc(18% - 9px) 0 18%;
  }
}

@media (min-width: 1250px) {
  .profile-effect {
    padding: 0px 18%;
  }
}

@media (min-width: 1680px) {
  .links-wrapper {
    padding: 0px calc(33% - 9px) 0 33%;
  }

  .links-wrapper-logo {
    padding: 0px calc(33% - 9px) 0 33%;
  }
}

@media (min-width: 1680px) {
  .profile-effect {
    padding: 0px 33%;
    overflow-y: auto;
  }
}

@media (min-width: 768px) {
  .link-container-4::before,
  .link-container-5::before,
  .link-container-6::before {
    bottom: -7px !important;
    left: -1px !important;
    right: -1px !important;
  }
}

@media (max-width: 768px) {
  .link-container-4,
  .link-container-5,
  .link-container-6 {
    min-height: 64px;
    border-radius: 31px !important;
    position: relative;
    border: 2px solid #d7dee4;
    display: flex;
    margin-bottom: 7px;
    background: #ebeef1;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .link-container-4::before,
  .link-container-5::before,
  .link-container-6::before {
    border-radius: 33px !important;
    left: -1px;
    right: -1px;
    bottom: -8px;
  }
}

.link-container-4::before,
.link-container-5::before,
.link-container-6::before {
  content: "";
  position: absolute;
  /* top: 0px; */
  background: repeating-linear-gradient(
    45deg,
    transparent 0,
    transparent 2px,
    #3d4043 2px,
    #3d4043 4px
  );
  /* border-color: #FFFFFF !important;  */
  z-index: -1;
  height: calc(100% - 7px);
  bottom: -7px !important;
  /* border: 2px  solid #3D4043;  */
  border-radius: 33px !important;
}

.link-container-4::before {
  background: repeating-linear-gradient(
    45deg,
    transparent 0,
    transparent 2px,
    #4680ff 2px,
    #4680ff 4px
  );
}

.link-container-5::before {
  background: #4680ff;
}

.link-container-6::before {
  background: linear-gradient(
    45deg,
    #ffc9ed 9%,
    #fff 9% 18%,
    #bff9fe 18% 27%,
    #a7684b 27% 36%,
    #3d4043 36% 45%,
    #f04166 45% 54%,
    #fa7539 54% 63%,
    #ffe85f 63% 72%,
    #01c8a7 72% 81%,
    #4e98ff 81% 90%,
    #b996f7 90% 100%
  );
}

.links-color {
  border: 2px solid #ccc;
  border-color: #ffffff;
}

.links-gradient {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 0px;
  gap: 8px;
  /* width: 98%; */
  min-height: 60px;
  font-weight: 500;
  font-size: 14px;
  background-color: #fafafa;
  color: black;
  border: none;
  text-align: center;
  border-radius: 33px;
  /* flex-wrap: wrap; */
}

.link-container- {
  border-radius: 30px;
  min-height: 60px;
  background-color: #ebeef1;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: center;
  width: 100%;
}

.link-container-undefined {
  border-radius: 30px;
  min-height: 60px;
  background-color: #ebeef1;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: center;
  width: 100%;
}

.link-container-1 {
  border-radius: 30px;
  min-height: 60px;
  background-color: #ebeef1;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  justify-content: center;
  width: 100%;
}

.link-container-2 {
  border-radius: 30px;
  background-color: #ebeef1;
  border: 2px solid #4680ff;
  min-height: 62px !important;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  justify-content: center;
  width: 100%;
}

.link-container-3 {
  min-height: 60px;
  box-shadow: 0px 4px 13px 0px #70869c3d;
  margin-bottom: 0px;
  border-radius: 33px;
  background-color: #ebeef1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

/* .link-container-4{
  min-height: 60px;
  background: repeating-linear-gradient(-45deg,transparent 0 3px,#0f0f0f 3px 6px);
  margin-bottom: 10px;
  border-radius: 33px;
}
.link-container-6{
  min-height: 60px;
  background: linear-gradient(
        45deg,
        #FFC9ED 9%,
        #FFF 9% 18%,
        #BFF9FE 18% 27%,
        #A7684B 27% 36%,
        #3D4043 36% 45%,
        #f04166 45% 54%,
        #FA7539 54% 63%,
        #FFE85F 63% 72%,
        #01C8A7 72% 81%,
        #4E98FF 81% 90%,
        #B996F7 90% 100%
    );
  margin-bottom: 10px;
  border-radius: 33px;
}
.link-container-5{
  background-color: #70869C;
  min-height: 60px;
  margin-bottom: 10px;
  border-radius: 33px;
  position: relative;
} */
.link-container-5::before {
  position: absolute;
  left: 2px;
  /* top: 6px; */
}

.link-container-7 {
  border: solid 4px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(90deg, #77cbea, #eb82ed, #8674db);
  background-origin: border-box;
  background-clip: content-box, border-box;
  border-radius: 33px;
  margin-bottom: 0;
  box-shadow: 2px 1000px 1px #ffffff inset;
  display: flex;
  min-height: 65px;
  align-items: center;
  justify-content: center;
}

.link-container-8 {
  border: solid 4px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(90deg, #fc9d0e, #b548f8, #619df8);
  background-origin: border-box;
  background-clip: content-box, border-box;
  border-radius: 33px;
  margin-bottom: 0;
  box-shadow: 2px 1000px 1px #ffffff inset;
  display: flex;
  align-items: center;
  min-height: 65px;
  justify-content: center;
}

.link-container-9 {
  border: solid 4px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(180deg, #60d9e9, #be33e9);
  background-origin: border-box;
  background-clip: content-box, border-box;
  border-radius: 33px;
  margin-bottom: 0;
  box-shadow: 2px 1000px 1px #ffffff inset;
  display: flex;
  align-items: center;
  min-height: 65px;
  justify-content: center;
}

/* .links:hover{
  transform: translate3d(0px, 0px, 0px) scale(1.015);
} */
.link-animated .link-animated-2,
.link-animated .link-animated-3,
.link-animated .link-animated-4,
.link-animated .link-animated-5,
.link-animated .link-animated-6,
.link-animated .link-animated-7,
.link-animated .link-animated-8,
.link-animated .link-animated-9 {
  margin-bottom: 7px;
}

.link-animated:hover .link-animated-2 {
  animation: shake 0.6s ease-in-out both;
}

.link-animated:hover .link-animated-2 .link-animated-text-2 {
  position: relative;
  animation: shakeText 0.5s ease-in-out both;
}
@keyframes shake {
  25% {
    transform: rotate(2deg);
  }
  50% {
    transform: rotate(-2deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes shakeText {
  0% {
    right: 8px;
  }
  33% {
    right: -8px;
  }
  66% {
    right: 8px;
  }
  100% {
    right: 0;
  }
}

.link-animated:hover .link-animated-3 {
  animation: float 1.8s ease-in-out both infinite;
}

.link-animated:hover .link-animated-3 .link-animated-text-3 {
  position: relative;
  animation: floatText 1.8s ease-in-out both infinite;
  animation-delay: 0.05s;
}

@keyframes float {
  0% {
    transform: translatey(-5px);
  }
  50% {
    transform: translatey(5px);
  }
  100% {
    transform: translatey(-5px);
  }
}

@keyframes floatText {
  0% {
    top: -2px;
  }
  50% {
    top: 2px;
  }
  100% {
    top: -2px;
  }
}

.link-animated .link-animated-4 {
  transform: perspective(500px) rotateY(0deg);
  overflow: hidden;
  transition: all 0.5s;
  border-radius: 31px;
}
.link-animated .link-animated-4 .link-animated-text-4 {
  transition: all 0.5s;
  text-shadow: 6px 3px 2px rgba(0, 0, 0, 0.2);
}
.link-animated:hover .link-animated-4 {
  transform: perspective(500px) rotateY(5deg);
}
.link-animated:hover .link-animated-4 .link-animated-text-4 {
  text-shadow: -6px 3px 2px rgba(0, 0, 0, 0.2);
}
.link-animated .link-animated-4::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    transparent,
    rgba(255, 255, 255, 0.925),
    transparent
  );
  left: -100%;
  z-index: 9;
  transition: all 0.5s;
}
.link-animated:hover .link-animated-4::before {
  left: 100%;
}
.link-animated:hover .link-animated-5 {
  animation: jump 0.75s ease-in-out both;
}

@keyframes jump {
  0% {
    transform: scale(1, 1) translate(0px, 0px);
  }

  30% {
    transform: scale(1, 0.8) translate(0px, 10px);
  }

  75% {
    transform: scale(1, 1.1) translate(0px, -15px);
  }

  100% {
    transform: scale(1, 1) translate(0px, 0px);
  }
}

.link-animated:hover .link-animated-6 {
  animation: shaker 0.75s ease-in-out both;
}

@keyframes shaker {
  0% {
    transform: rotate(0deg);
  }

  29% {
    transform: rotate(0deg);
  }

  30% {
    transform: rotate(1deg);
  }

  31% {
    transform: rotate(2deg);
  }

  33% {
    transform: rotate(1deg);
  }

  35% {
    transform: rotate(0deg);
  }

  37% {
    transform: rotate(-1deg);
  }

  39% {
    transform: rotate(-2deg);
  }

  41% {
    transform: rotate(-1deg);
  }

  43% {
    transform: rotate(0deg);
  }

  45% {
    transform: rotate(1deg);
  }

  47% {
    transform: rotate(2deg);
  }

  49% {
    transform: rotate(1deg);
  }

  51% {
    transform: rotate(0deg);
  }

  53% {
    transform: rotate(-1deg);
  }

  55% {
    transform: rotate(-2deg);
  }

  57% {
    transform: rotate(-1deg);
  }

  59% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.link-animated:hover .link-animated-7 {
  animation: flash 1s both;
}

@keyframes flash {
  from,
  50%,
  to {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

.link-animated:hover .link-animated-8 {
  animation-duration: 1s !important;
  animation-iteration-count: 1 !important;
  transition-duration: 1s !important;
  animation-name: swing;
  transform-origin: top center;
}

@keyframes swing {
  20% {
    transform: rotate(5deg);
  }

  40% {
    transform: rotate(-6deg);
  }

  60% {
    transform: rotate(5deg);
  }

  80% {
    transform: rotate(-5deg);
  }

  to {
    transform: rotate(0deg);
  }
}

.link-animated:hover .link-animated-9 {
  animation-name: rubberBand;
  animation-duration: 1s !important;
  animation-iteration-count: 1 !important;
  transition-duration: 1s !important;
}

@keyframes rubberBand {
  0% {
    transform: scaleX(1);
  }

  30% {
    transform: scale3d(1.08, 0.75, 1);
  }

  40% {
    transform: scale3d(0.75, 1.08, 1);
  }

  50% {
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, 0.95, 1);
  }

  to {
    transform: scaleX(1);
  }
}

.share {
  width: 48px !important;
  min-height: 48px !important;
  justify-content: center;
}

.subscribe {
  width: 170px !important;
  min-height: 48px !important;
  justify-content: center;
}

.shareLabel {
  display: flex;
  align-items: center;
  border: 1px solid #8c8c8e;
  border-radius: 4px;
  width: 75%;
  margin: 0;
  height: 42px;
  overflow: hidden;
  padding: 4px 8px;
}

.profileName {
  margin: 0px;
  color: rgb(0, 0, 0);
  font-weight: 700;
  font-size: 20px;
  /* text-align: center; */
  line-height: 1.5;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.profilebio {
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 4px;
}

.biotext {
  padding: 0px;
  margin: 0px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}

.profile-title {
  margin-top: 16px;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 8px;
}

.profile-description {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;
}

.bioLinks-link-title {
  font-size: 16px;
  margin: 0;
  text-align: start;
  overflow-wrap: anywhere;
}

.iNuLQM {
  overflow: hidden;
  position: absolute;
  /* top: 50%; */
  top: 56%;
  transform: translateY(-50%);
  pointer-events: none;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 48px;
  height: 48px;
  padding: 5px;
  /* margin: 3%; */
  margin: left;
  margin-left: 15px;
  margin-bottom: 60px;
  text-align: center;
}

.gZIEiX {
  left: 4px;
  border-radius: 4px;
  width: 48px;
  height: 48px;
}

/* img{
  max-width: 100%;
  height: auto;
} */

.icon {
  margin: 4px 8px;
  width: 44px;
  height: 44px;
  position: absolute;
  margin-top: -12px;
}

.bio-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.btnDownload {
  border-radius: 18px !important;
  border: none !important;
  background-color: #f3f5f7 !important;
  padding: 7px 21px !important;
}

.btnDownload:hover {
  color: #626c76 !important;
}

.share-modalBody {
  padding: 25px !important;
}

.subscribe-modalBody {
  display: flex;
  justify-content: center;
  padding: 0 20%;
  flex-direction: column;
}

.footer-logo {
  max-width: 100%;
}

@media (max-width: 350px) {
  .share {
    width: 43px !important;
    min-height: 43px !important;
  }

  .subscribe {
    width: 150px !important;
    min-height: 43px !important;
  }

  .profile-title {
    font-size: 16px;
  }

  .profile-description {
    font-size: 15px;
  }

  .bioLinks-link-title {
    font-size: 14px;
  }

  .share-modalBody {
    padding: 10px !important;
  }

  .subscribe-modalBody {
    padding: 0 10%;
  }

  .footer-logo {
    max-width: 85%;
  }
}
