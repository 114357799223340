//
// _card.scss
//

.card {
  margin-bottom: $grid-gutter-width;
  // box-shadow: $box-shadow;
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 15px;
  margin: 0 0 7px 0; /*rtl:7px 0 0 0 */
  font-weight: $fw-medium;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 24px;
  font-size: 13px;
}
